import React, { useLayoutEffect } from 'react';

import { useInternshipForm } from '@/components/blocks/Internship2024/providers/InternshipProvider';
import { IStageItem } from '@/components/blocks/Internship2024/types/stages';

import cn from './style.module.sass';

function StageItem({ number, stage, description }: IStageItem) {
    const { handleOpen } = useInternshipForm();
    useLayoutEffect(() => {
        if (description.includes('stageModal')) {
            document.getElementsByClassName('stageModal')[0].addEventListener('click', () => handleOpen());
        }
        return () => {
            document.getElementsByClassName('stageModal')[0].removeEventListener('click', () => handleOpen());
        };
    }, []);
    return (
        <div className={cn.item}>
            <div className={cn.stage}>
                <div className={cn.decor}>{'{'}</div>
                <div className={cn.number}>{number}</div>
                <div className={cn.decor}>{'}'}</div>
            </div>
            <div className={cn.text}>
                <div className={cn.title} dangerouslySetInnerHTML={{ __html: stage }} />
                <div className={cn.desc} dangerouslySetInnerHTML={{ __html: description }} />
            </div>
        </div>
    );
}

export default StageItem;
