import clsx from 'clsx';
import React from 'react';

import { IInternArrow } from '@/components/blocks/Internship2024/types/jobs';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function PrevArrow({ gtag, listLength, swiperRef }: IInternArrow) {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef.current?.swiper?.slidePrev();
        if (gtag?.action) event(gtag);
    };

    return (
        <svg
            width="76"
            height="76"
            viewBox="0 0 76 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(cn.customArrow, cn.customArrowLeft)}
            onClick={onClick}
        >
            <rect
                x="75.5"
                y="75.5"
                width="75"
                height="75"
                rx="37.5"
                transform="rotate(180 75.5 75.5)"
                stroke="#001E45"
                strokeOpacity="1"
            />
            <path
                d="M25.2 38L33.7334 46.5333M25.2 38L33.7334 29.4667M25.2 38H51.8667"
                stroke="#001E45"
                strokeOpacity="1"
            />
        </svg>
    );
}

export default PrevArrow;
