import React, { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';

import CustomImage from '@/components/CustomImage';
import CustomSwiper from '@/components/CustomSwiper';

import cn from './style.module.sass';

const SLIDES = [
    {
        img: '/img-next/svg/internship2024/cat-slider/cat-1.svg',
        bg: '/img-next/svg/internship2024/cat-slider/bg-1.svg',
        text: '&laquo;Примерь&raquo; профессию',
    },
    {
        img: '/img-next/svg/internship2024/cat-slider/cat-2.svg',
        bg: '/img-next/svg/internship2024/cat-slider/bg-2.svg',
        text: 'Открой<br/>новые возможности',
    },
    {
        img: '/img-next/svg/internship2024/cat-slider/cat-3.svg',
        bg: '/img-next/svg/internship2024/cat-slider/bg-3.svg',
        text: 'Профессия рядом',
    },
    {
        img: '/img-next/svg/internship2024/cat-slider/cat-4.svg',
        bg: '/img-next/svg/internship2024/cat-slider/bg-4.svg',
        text: 'Узнай код своей карьеры',
    },
];

const CatSlider = () => {
    const settings = useMemo(
        () => ({
            className: cn.slider,
            slidesPerView: 1,
            loop: true,
            pagination: false,
            watchOverflow: true,
            effect: 'fade' as 'fade' | 'slide' | 'cube' | 'coverflow' | 'flip',
            fadeEffect: { crossFade: true },
            autoplay: true,
        }),
        []
    );

    return (
        <div className={cn.wrapper}>
            <CustomSwiper {...settings}>
                {SLIDES?.map((item, index) => (
                    <SwiperSlide key={item.img} className={cn.slide}>
                        <div className={cn.ellipse}>
                            <CustomImage src={item.bg} alt="Эллипс" fill />
                        </div>
                        <div className={cn.cat}>
                            <CustomImage src={item.img} alt="Кот" fill />
                        </div>
                        <div className={cn.title} dangerouslySetInnerHTML={{ __html: item.text }} />
                    </SwiperSlide>
                ))}
            </CustomSwiper>
        </div>
    );
};

export default CatSlider;
