import clsx from 'clsx';
import React, { useState } from 'react';

import AnimatedComponent from '@/components/Animation';
import Button from '@/components/blocks/Internship2024/Button';
import { GTAG } from '@/components/blocks/Internship2024/data/gtag';
import InternshipForm from '@/components/blocks/Internship2024/InternshipForm';
import { IJob } from '@/components/blocks/Internship2024/types/jobs';
import VacancyInfoItem from '@/components/blocks/Internship2024/VacancyInfoItem';

import cn from './style.module.sass';

interface IVacancyInfoProps {
    vacancy: IJob;
}

const VacancyInfo = ({ vacancy }: IVacancyInfoProps) => {
    const [open, setOpen] = useState(false);

    return (
        <div className={cn.wrapper}>
            <div className={cn.title}>{vacancy.vacancy}</div>
            <div className={cn.content}>
                {vacancy.content.map(item => (
                    <VacancyInfoItem item={item} key={item.subtitle.join('_')} />
                ))}
            </div>
            {!open && (
                <div className={cn.button}>
                    <Button
                        onClick={() => setOpen(!open)}
                        customClass={cn.button}
                        isNewDesign
                        type="button"
                        label="Подать заявку"
                    />
                </div>
            )}
            <AnimatedComponent className={clsx(cn.form, open && cn.fadeIn)}>
                <InternshipForm
                    fieldGtag={GTAG.formModalField}
                    submitGtag={GTAG.formModalSuccess}
                    isOpen={open}
                    predefinedVacancy={{ label: vacancy.vacancy, value: vacancy.vacancy }}
                />
            </AnimatedComponent>
        </div>
    );
};

export default VacancyInfo;
