import React from 'react';

import FooterLeft from '@/components/blocks/Internship2024/FooterLeft';
import FooterRight from '@/components/blocks/Internship2024/FooterRight';

import cn from './style.module.sass';

function Footer() {
    return (
        <div className={cn.wrapper}>
            <div className={cn.container}>
                <div className={cn.grid}>
                    <div className={cn.left}>
                        <FooterLeft />
                    </div>
                    <div className={cn.right}>
                        <FooterRight />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Footer;
