import clsx from 'clsx';
import React, { useRef } from 'react';

import Button from '@/components/blocks/Internship2024/Button';
import { CrossIcon } from '@/components/blocks/Internship2024/CrossIcon';
import InternMenu from '@/components/blocks/Internship2024/Menu';
import { useInternshipForm } from '@/components/blocks/Internship2024/providers/InternshipProvider';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

interface IProps {
    closeMenu: () => void;
    active?: boolean;
    btnLabel: string;
}

function MobileMenu({ closeMenu, active, btnLabel }: IProps) {
    const { handleOpen } = useInternshipForm();
    const ref = useRef(null);

    useOnClickOutside(ref, () => closeMenu());

    return (
        <>
            <div className={clsx(cn.overlay, active && cn.active)} />
            <div className={clsx(cn.menu, active && cn.active, 'mobileMenu')} ref={ref}>
                <div className={cn.top}>
                    <InternMenu closeMenu={closeMenu} dark />
                    <div className={cn.cross}>
                        <CrossIcon handleClick={closeMenu} />
                    </div>
                </div>
                <div className={cn.bottom}>
                    <Button type="button" label={btnLabel} isDark onClick={handleOpen} />
                </div>
            </div>
        </>
    );
}

export default MobileMenu;
