import React from 'react';

import cn from './style.module.sass';

const HasQuestion = () => {
    const email = 'job@pochtabank.ru';

    return (
        <div className={cn.wrapper}>
            <div className={cn.title}>Остались вопросы?</div>
            <div className={cn.text}>
                Напиши нам на{' '}
                <a className={cn.email} href={`mailto:${email}`}>
                    {email}
                </a>
            </div>
        </div>
    );
};

export default HasQuestion;
