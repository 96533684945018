import cloneDeep from 'lodash/cloneDeep';

import { IJob } from '@/components/blocks/Internship2024/types/jobs';

export const JOBS_ORDER = [
    'Интернет-продажи',
    'Связи с общественностью ',
    'Аналитика',
    'CRM',
    'HR',
    'Информационная безопасность',
    'Аналитика онлайн-продаж',
];

export const sortJobByCategory = (unsortedJobs: IJob[]) => {
    const copy = cloneDeep(unsortedJobs);
    return copy.sort((jobA: IJob, jobB: IJob) => JOBS_ORDER.indexOf(jobA.category) - JOBS_ORDER.indexOf(jobB.category));
};

export const jobs: IJob[] = [
    {
        vacancy: 'Стажёр в отдел криптографической защиты',
        category: 'Информационная безопасность',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'подготовка данных для изготовления уникальных ключей электронной подписи',
                    'участие в проекте по созданию технологического УЦ',
                    'обучение сотрудников эксплуатации ключевой информации',
                    'документальное оформление выпущенных ключей',
                    'участие в создании сертификатов для защищенного соединения c web-ресурсами банка',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'общее понимание симметричной и асимметричной криптографии и стандартов PKI',
                    'общее понимание принципов работы удостоверяющих центров',
                    'внимательность к деталям',
                    'желание развиваться в направлении криптографии и электронной подписи',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в группу аналитики онлайн-продаж',
        category: 'Аналитика онлайн-продаж',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'анализ продаж по всем банковским продуктам',
                    'формирование необходимой отчётности по продажам',
                    'создание презентаций по результатам проведённой аналитики',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'аналитический склад ума',
                    'знание SQL, уверенное владение Excel',
                    'желание развиваться в области аналитики онлайн-продаж и продуктовой аналитики',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в дирекцию интернет-продаж',
        category: 'Интернет-продажи',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'подготовка и оценка медиаплана для размещения интернет-рекламы',
                    'оценка эффективности интернет-рекламы',
                    'управление рекламными кампаниями в сети',
                    'формирование отчетов и предложений по улучшению результативности',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'понимания принципов работы контекстной, таргетированной интернет-рекламы и CPA-привлечения',
                    'опыт работы в Power Point, уверенное владение Excel',
                    'высокую работоспособность и проактивность',
                    'желание развиваться в области интернет-привлечения',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в направление по связям с общественностью',
        category: 'Связи с общественностью ',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'участие в подготовке информационных PR-материалов банка',
                    'мониторинг информационного поля',
                    'разработка контента для официальных сообществ банка в социальных сетях',
                ],
            },
            {
                title: ' ОЖИДАЕМ:',
                subtitle: [
                    'грамотную письменную и устную речь',
                    'умение писать тексты разного формата',
                    'уверенное владение Excel и Power Point',
                    'коммуникабельность и проактивность',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в управление дистанционных продаж',
        category: 'Аналитика',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'анализ запускаемых пилотов',
                    'создание презентаций по результатам проведённой аналитики',
                    'участие в разработке отчётности Excel',
                    'написание SQL-скриптов в БД Oracle',
                    'разработка BI-дашбордов в Power BI',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'базовые навыки для работы с SQL, Excel',
                    'желание развиваться в направлении аналитики и погружаться в банковские процессы',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в департамент CRM',
        category: 'CRM',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'анализ клиентских данных  для выявления закономерностей и предложения наиболее интересных продуктов и услуг',
                    'подготовка релевантных сегментов клиентов для продвижения банковских продуктов и услуг',
                    'запуск продуктовых и информационных CRM-кампаний',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'базовые навыки для работы с  SQL, Excel',
                    'желание развиваться в направлении аналитики и CRM',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в отдел методологии информационной безопасности',
        category: 'Информационная безопасность',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'работа с регламентирующими процедурами управления риском ИБ',
                    'работа со сведениями о событиях риска ИБ',
                    'работа с автоматизированной системой управления операционным риском',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'базовые навыки для работы в Excel',
                    'стремление к изучению технологических процессов, реализуемых кредитными организациями',
                    'интерес к изучению основ риск-менеджмента в части управления рисками ИБ',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в отдел мониторинга информационной безопасности',
        category: 'Информационная безопасность',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: ['участие в анализе событий ИБ', 'оптимизация работы средств защиты'],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'желание развиваться в направлении защиты информации',
                    'понимание базовых ИТ-технологий',
                    'базовые знания в области функционирования средств защиты информации',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в направление развития бренда работодателя',
        category: 'HR',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'участие в мероприятиях по развитию бренда работодателя',
                    'работа с креативом, текстами, исследованиями',
                    'поддержка работы проектов HR',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'желание развиваться в направлении HR и бренда работодателя',
                    'грамотность, внимание к деталям, чувство вкуса, системный подход',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в департамент по работе с ключевыми клиентами',
        category: 'Аналитика',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'участие в задачах по работе с АО «Почта России», одного из основных акционеров и партнеров Почта Банка',
                    'подготовка отчётности: мониторинг показателей продаж, создание презентаций',
                ],
            },
            {
                title: ' ОЖИДАЕМ:',
                subtitle: [
                    'базовые навыки для работы в SQL, Excel, Power Point',
                    'желание развиваться в направлении аналитики и погружаться в банковские процессы',
                ],
            },
        ],
    },
    {
        vacancy: 'Стажёр в казначейство',
        category: 'Аналитика',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'участие в технологических задач: расчёт и прогноз обязательных нормативов Банка России',
                    'работа с банковским балансом и банковскими продуктами, инструментами финансового рынка',
                    'участие в ценообразовании всех процентных продуктов банка',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: ['уверенный пользователь Excel, SQL', 'желание развиваться в области казначейства'],
            },
        ],
    },
    {
        vacancy: 'Стажёр в службу по работе с проблемными активами',
        category: 'Аналитика',
        content: [
            {
                title: 'С ЧЕМ БУДЕТ РАБОТАТЬ:',
                subtitle: [
                    'участие в проектах развития процессов работы с проблемными активами',
                    'работа, нацеленная на уменьшение рисков невозврата и классификации клиентов по данному признаку',
                    'проведение тестирования корректности работы стратегии по работе с проблемными активами',
                    'формирование требований к доработкам процессов работы  с проблемными активами',
                    'участие в обсуждении доработок с ИТ-подразделениями',
                ],
            },
            {
                title: 'ОЖИДАЕМ:',
                subtitle: [
                    'базовые навыки работы в SQL, Excel, JIRA',
                    'знание гибких методик управления проектами',
                    'желание развиваться в направлении бизнес-анализа и процессах разработки ПО и погружаться в банковские процессы',
                ],
            },
        ],
    },
];
