import React, { useMemo } from 'react';
import { SwiperSlide } from 'swiper/react';

import Button from '@/components/blocks/Internship2024/Button';
import { GTAG } from '@/components/blocks/Internship2024/data/gtag';
import { useInternshipForm } from '@/components/blocks/Internship2024/providers/InternshipProvider';
import CustomImage from '@/components/CustomImage';
import CustomSwiper from '@/components/CustomSwiper';
import { useResize } from '@/hooks/useResize';
import { TABLET_SMALL_WIDTH } from '@/utils/constants';
import { universalEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

const SLIDES = ['/img-next/svg/internship2024/hero/hero-1.svg', '/img-next/svg/internship2024/hero/hero-2.svg'];

function Hero() {
    const width = useResize();

    const isMobile = width < TABLET_SMALL_WIDTH;

    const settings = useMemo(
        () => ({
            className: cn.slider,
            slidesPerView: 1,
            loop: true,
            pagination: false,
            watchOverflow: true,
            effect: 'fade' as 'fade' | 'slide' | 'cube' | 'coverflow' | 'flip',
            fadeEffect: { crossFade: true },
            autoplay: true,
        }),
        []
    );

    const { handleOpen } = useInternshipForm();

    const openModal = () => {
        universalEvent(GTAG.hero);
        handleOpen();
    };

    return (
        <div className={cn.wrapper}>
            <div className={cn.grid}>
                <div className={cn.left}>
                    <div className={cn.title}>Код твоей карьеры</div>
                    <div className={cn.desc}>
                        Стажировка {isMobile ? <br /> : null}в Почта Банке 2024
                        <div className={cn.styledDesc}>
                            {'{'} c 1 июля по 30 сентября {'}'}
                        </div>
                    </div>
                    <Button
                        onClick={openModal}
                        customClass={cn.button}
                        isNewDesign
                        type="button"
                        label="Хочу в команду"
                    />
                </div>
                <div className={cn.right}>
                    <CustomSwiper {...settings}>
                        {SLIDES?.map(item => (
                            <SwiperSlide key={item} className={cn.item}>
                                <CustomImage src={item} alt="Почтабанк" fill />
                            </SwiperSlide>
                        ))}
                    </CustomSwiper>
                </div>
            </div>
        </div>
    );
}

export default Hero;
