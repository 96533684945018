import React from 'react';

import { socialIntern as social } from '@/components/blocks/Internship2024/data/social';
import ImageInfo from '@/components/blocks/Internship2024/ImageInfo';
import CustomImage from '@/components/CustomImage';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function FooterLeft() {
    return (
        <div className={cn.wrapper}>
            <div className={cn.logo}>
                <a href="/">
                    <CustomImage
                        src="/img-next/svg/hr-landing/logo-footer.svg"
                        alt="Intern Landing"
                        width="97"
                        height="51"
                    />
                </a>
            </div>
            <div className={cn.social}>
                {social?.map(({ link, name, image, gtag }) => (
                    <a
                        key={link}
                        href={link}
                        target="_blank"
                        className={cn.item}
                        rel="noopener noreferrer"
                        onClick={() => event(gtag)}
                    >
                        {image && <CustomImage src={image} alt={name} width={40} height={40} />}
                    </a>
                ))}
            </div>
            <div className={cn.images}>
                <ImageInfo />
            </div>
        </div>
    );
}

export default FooterLeft;
