export const internship = [
    {
        icon: '/img-next/svg/internship2024/internship/1.svg',
        title: 'Профессиональные наставники, поддержка и&nbsp;забота',
    },
    {
        icon: '/img-next/svg/internship2024/internship/2.svg',
        title: 'Прокачка твоих навыков и&nbsp;реальные проекты',
    },
    {
        icon: '/img-next/svg/internship2024/internship/3.svg',
        title: 'Возможность стать частью большой команды',
    },
];
