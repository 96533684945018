import Image from 'next/image';
import React, { useRef, useState } from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import cn from './style.module.sass';

function VideoPreview() {
    const [isOpen, setIsOpen] = useState(false);

    const ref = useRef(null);

    const handleOpen = () => {
        setIsOpen(true);
    };

    const handleClose = () => {
        setIsOpen(false);
    };

    useOnClickOutside(ref, handleClose);
    return (
        <div className={cn.wrapper}>
            <div className={cn.title}>Как проходит стажировка</div>
            <div className={cn.video} ref={ref} onClick={handleOpen} role="presentation">
                <div className={cn.preview}>
                    <Image src="/img-next/jpg/internship2024/video/preview.jpg" fill alt="preview" />
                </div>
                <button type="button" className={cn.play}>
                    <Image src="/img-next/svg/internship2024/video/play.svg" width={116} height={116} alt="youTube" />
                </button>
            </div>
            <ModalWrapper isOpen={isOpen} handleClose={handleClose} handleOpen={handleOpen} wrapperClassName={cn.modal}>
                <div className={cn.iframe}>
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/_KZDgcYqiN0?si=faXa2X_jUmZJ8Ywh"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    />
                </div>
            </ModalWrapper>
        </div>
    );
}

export default VideoPreview;
