import clsx from 'clsx';
import React, { useState } from 'react';

import { BurgerIcon } from '@/components/blocks/Internship2024/BurgerIcon';
import InternMenu from '@/components/blocks/Internship2024/Menu';
import MobileMenu from '@/components/blocks/Internship2024/MobileMenu';
import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

function Header() {
    const [isMenuShown, showMenu] = useState(false);

    const show = () => showMenu(true);
    const hide = () => showMenu(false);

    return (
        <div className={cn.header}>
            <div className={cn.grid}>
                <div className={clsx(cn.item, cn.itemLogo)}>
                    <div className={cn.logo}>
                        <a href="/">
                            <CustomImage
                                src="/img-next/svg/internship2024/logo.svg"
                                alt="Intern Landing"
                                width="111"
                                height="52"
                            />
                        </a>
                    </div>
                </div>

                <div className={clsx(cn.item, cn.itemMenu)}>
                    <InternMenu dark />
                </div>

                <div className={clsx(cn.item, cn.itemButton)}>
                    <div className={cn.burger}>
                        <BurgerIcon handleClick={show} />
                    </div>
                </div>
            </div>
            <MobileMenu btnLabel="Подать заявку" active={isMenuShown} closeMenu={hide} />
        </div>
    );
}

export default Header;
