import React from 'react';

import cn from './style.module.sass';

export function ArrowIcon() {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="20" cy="20" r="19.5" fill="white" stroke="#E0E4F0" className={cn.circle} />
            <path
                className={cn.path}
                d="M18.2224 25.3333L23.5557 20L18.2224 14.6667"
                stroke="#001E45"
                strokeWidth="2"
                strokeLinecap="square"
            />
        </svg>
    );
}
