import 'swiper/components/navigation/navigation.min.css';

import uniq from 'lodash/uniq';
import React, { useMemo, useRef, useState } from 'react';
// import required modules
import SwiperCore, { Navigation, Thumbs } from 'swiper';
import { SwiperSlide } from 'swiper/react';

import { jobs, sortJobByCategory } from '@/components/blocks/Internship2024/data/jobs';
import JobsCategory from '@/components/blocks/Internship2024/Jobs/JobsCategory';
import NextArrow from '@/components/blocks/Internship2024/Jobs/JobsSlider/NextArrow';
import PrevArrow from '@/components/blocks/Internship2024/Jobs/JobsSlider/PrevArrow';
import JobsVacancy from '@/components/blocks/Internship2024/Jobs/JobsVacancy';
import { IJob } from '@/components/blocks/Internship2024/types/jobs';
import VacancyInfo from '@/components/blocks/Internship2024/VacancyInfo';
import CustomSwiper from '@/components/CustomSwiper';
import ModalWrapper from '@/components/modal/ModalWrapper';
import { ISwiperRef } from '@/types/animatedPromo/item';

import cn from './style.module.sass';

SwiperCore.use([Thumbs, Navigation]);

function JobsSlider() {
    const [open, setOpen] = useState(false);
    const getVacanciesByCategory = (category: string) => jobs.filter(item => item.category === category);
    const getInfoByVacancy = (vacancyName: string) => jobs.find(item => item.vacancy === vacancyName);
    const [activeVacancies, setActiveVacancies] = useState<IJob[]>(getVacanciesByCategory('Аналитика'));
    const [activeVacancy, setActiveVacancy] = useState<IJob | null>(null);
    const categories = uniq(sortJobByCategory(jobs).map(item => item.category));

    const swiperRefDots = useRef<ISwiperRef>(null);

    const settingsDots = useMemo(
        () => ({
            loop: false,
            autoplay: false,
            slidesPerView: 'auto',
            spaceBetween: 12,
            slideToClickedSlide: true,
            onActiveIndexChange: (swiper: { activeIndex: number; realIndex: number }) => {
                const currentCategory = categories[swiper.realIndex];
                const newVacancies = getVacanciesByCategory(currentCategory);
                setActiveVacancies(newVacancies);
            },
        }),
        []
    );

    const handleClose = () => {
        setOpen(false);
        setActiveVacancy(null);
    };

    const handleOpen = (vacancy: string) => {
        const currentVacancy = getInfoByVacancy(vacancy);
        setActiveVacancy(currentVacancy);
        setOpen(true);
    };

    return (
        <div className={cn.currentSlider}>
            <div className={cn.currentSliderTop}>
                {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
                {/* @ts-ignore */}
                <CustomSwiper {...settingsDots} ref={swiperRefDots}>
                    {/* МИКРО КОСТЫЛЬ. SWIPER ПРИ LOOP:FALSE НЕ ДАЕТ ПЕРЕЙТИ НА ПОСЛЕДНИЕ СЛАЙДЫ ЕСЛИ ПОСЛЕ НИХ НЕТ СЛАЙДОВ ДЛЯ ЗАПОЛНЕНИЯ ПУСТОТЫ */}
                    {[...categories, '', '', '', '', '', ''].map(category => (
                        <SwiperSlide key={category}>
                            <JobsCategory category={category} />
                        </SwiperSlide>
                    ))}
                </CustomSwiper>
                <PrevArrow listLength={jobs?.length} swiperRef={swiperRefDots} />
                <NextArrow listLength={jobs?.length} swiperRef={swiperRefDots} />
            </div>

            <div className={cn.vacancies}>
                {activeVacancies.map(vacancy => (
                    <div className={cn.vacancy} key={vacancy.vacancy}>
                        <JobsVacancy vacancy={vacancy.vacancy} onClick={handleOpen} />
                    </div>
                ))}
            </div>
            {activeVacancy && (
                <ModalWrapper
                    isOpen={open}
                    handleClose={handleClose}
                    className={cn.innerModal}
                    wrapperClassName={cn.outerModal}
                    isStandartModalWidth={false}
                >
                    <VacancyInfo vacancy={activeVacancy} />
                </ModalWrapper>
            )}
        </div>
    );
}

export default JobsSlider;
