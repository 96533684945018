import clsx from 'clsx';
import React, { useState } from 'react';

import ModalWrapper from '@/components/modal/ModalWrapper';

import cn from './style.module.sass';

const LINKS = [
    { href: 'https://www.freepik.com/free-vector/cat-mascot-cartoon_136471504.htm', label: 'Cat mascot cartoon' },
    { href: 'https://www.freepik.com/free-vector/cat-mascot-cute_136485232.htm', label: 'Cat mascot cute' },
    {
        href: 'https://www.freepik.com/free-vector/cat-mascot-animal-illustration_136472592.htm#&position=1&from_view=user&uuid=a68ed1c9-eeda-4315-8a88-c73711c6d3d0',
        label: 'Free сat mascot animal',
    },
    { href: 'https://www.freepik.com/free-vector/cat-mascot-isolated_136489748.htm', label: 'Free vector cat mascot' },
    {
        href: 'https://www.freepik.com/free-vector/cat-mascot-domestic_136487999.htm',
        label: 'Free cat mascot domestic',
    },
    {
        href: 'https://www.freepik.com/free-vector/cat-mascot-with-spots_136485471.htm',
        label: 'Free cat mascot with spots',
    },
    {
        href: 'https://www.freepik.com/free-vector/cat-mascot-illustration_169538299.htm',
        label: 'Free vector cat mascot',
    },
    { href: 'https://www.freepik.com/free-vector/cat-mascot-feline_169539732.htm', label: 'Cat mascot feline' },
];

function ImageInfo() {
    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    return (
        <div className={cn.wrapper}>
            <div role="presentation" className={clsx(cn.info)} onClick={handleOpen}>
                Информация об изображениях, использованных на сайте.
            </div>
            <ModalWrapper
                isStandartModalWidth={false}
                isOpen={open}
                handleClose={handleClose}
                handleOpen={handleOpen}
                wrapperClassName={cn.modal}
            >
                <div className={cn.text}>
                    В&nbsp;проекте использованы изображения с&nbsp;сайта{' '}
                    <a className={cn.link} href="https://www.freepik.com" target="_blank" rel="noreferrer">
                        https://www.freepik.com
                    </a>{' '}
                    автора studiogstock:{' '}
                    {LINKS.map(item => (
                        <React.Fragment key={item.href}>
                            <a className={cn.link} href={item.href} target="_blank" rel="noreferrer">
                                {item.label}
                            </a>
                            {', '}
                        </React.Fragment>
                    ))}
                    .
                </div>
            </ModalWrapper>
        </div>
    );
}

export default ImageInfo;
