import React from 'react';

import { GTAG } from '@/components/blocks/Internship2024/data/gtag';
import { stages } from '@/components/blocks/Internship2024/data/stages';
import InternshipForm from '@/components/blocks/Internship2024/InternshipForm';
import StageItem from '@/components/blocks/Internship2024/Stages/StageItem';

import cn from './style.module.sass';

function Stages() {
    return (
        <div className={cn.wrapper} id="stages">
            <div className={cn.title}>Пройди 5&nbsp;этапов и&nbsp;ты&nbsp;у&nbsp;цели</div>
            <div className={cn.list}>
                {stages.map(item => (
                    <StageItem
                        key={item.stage}
                        number={item.number}
                        stage={item.stage}
                        description={item.description}
                    />
                ))}
            </div>
            <div className={cn.form}>
                <InternshipForm
                    fieldGtag={GTAG.formInlineField}
                    submitGtag={GTAG.formInlineSuccess}
                    isOpen
                    title="Заявка на стажировку"
                    messageInModal
                />
            </div>
        </div>
    );
}

export default Stages;
