import clsx from 'clsx';
import React from 'react';

import { IInternArrow } from '@/components/blocks/Internship2024/types/jobs';
import { event } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function NextArrow({ gtag, listLength, swiperRef }: IInternArrow) {
    if (listLength < 4) return null;

    const onClick = () => {
        swiperRef?.current?.swiper?.slideNext();
        if (gtag?.action) event(gtag);
    };

    return (
        <svg
            width="76"
            height="76"
            viewBox="0 0 76 76"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={clsx(cn.customArrow, cn.customArrowRight)}
            onClick={onClick}
        >
            <rect x="0.5" y="0.5" width="75" height="75" rx="37.5" stroke="#001E45" strokeOpacity="1" />
            <path
                d="M50.8 38L42.2666 29.4667M50.8 38L42.2666 46.5333M50.8 38H24.1333"
                stroke="#001E45"
                strokeOpacity="1"
            />
        </svg>
    );
}

export default NextArrow;
