import clsx from 'clsx';
import uniq from 'lodash/uniq';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';

import { jobs } from '@/components/blocks/Internship2024/data/jobs';
import ModalWrapper from '@/components/modal/ModalWrapper';
import Button from '@/components/UI/Button';
import CustomSelect from '@/components/UI/CustomSelect';
import { FileUpload } from '@/components/UI/FileUpload';
import Input from '@/components/UI/Input';
import Title from '@/components/UI/Title';
import Attribution from '@/domain/attribution/attribution';
import LNDAttribution from '@/domain/attribution/lnd-attribution';
import { InnerFormValues, useValidationSchema } from '@/hooks/useValidationSchema';
import { IBXResponseForms } from '@/types/requests';
import { ISelectElement } from '@/types/select';
import { getFormDataFromHookForm } from '@/utils/forms/getFormDataFromHookForm';
import { getFormErrors } from '@/utils/forms/getFormErrors';
import { GTagEvent, universalEvent } from '@/components/shared/utilities/analytics/metrics';
import { removePropertyFromObject } from '@/utils/removePropertyFromObject';
import { apiRequest } from '@/utils/request';

import cn from './style.module.sass';

export interface IInternshipForm {
    title?: string;
    predefinedVacancy?: ISelectElement<string>;
    isOpen?: boolean;
    needFocus?: boolean;
    messageInModal?: boolean;
    submitGtag: GTagEvent;
    fieldGtag: GTagEvent;
}

const EDUCATION_FORMS = [
    { value: 'Очная', label: 'Очная' },
    { value: 'Заочная', label: 'Заочная' },
];

const COURSES = [
    { value: '3 курс', label: '3 курс' },
    { value: '4 курс', label: '4 курс' },
    { value: '5 курс', label: '5 курс' },
];

function InternshipForm({
    isOpen = false,
    predefinedVacancy = null,
    needFocus = false,
    messageInModal = false,
    submitGtag,
    fieldGtag,
}: IInternshipForm) {
    const [message, setMessage] = useState<string | null>(null);
    const titleRef = useRef<HTMLDivElement | null>(null);

    const formResolver = useValidationSchema([
        { name: 'direction', type: 'option', required: true },
        { name: 'univercity', type: 'required', required: true },
        { name: 'city', type: 'required', required: true },
        { name: 'class', type: 'option', required: true },
        { name: 'type_of_education', type: 'option', required: true },
        { name: 'fio', type: 'fio', required: true },
        { name: 'phone', type: 'phone', required: true },
        { name: 'email', type: 'email', required: true },
        { name: 'file', type: 'arrayOfStringsOptional', required: false },
    ]);

    const methods = useForm<InnerFormValues>({
        resolver: formResolver,
    });

    const {
        register,
        handleSubmit,
        formState: { errors, isSubmitting },
        setValue,
        trigger,
        reset,
    } = useForm<InnerFormValues>({
        resolver: formResolver,
        mode: 'onBlur',
        reValidateMode: 'onBlur',
        shouldFocusError: false,
    });

    const options = useMemo(() => {
        const uniqVacancies = uniq(jobs.map(item => item.vacancy));
        return uniqVacancies.map(category => ({
            value: category,
            label: category,
        }));
    }, []);

    const closeMessage = () => {
        setMessage(null);
    };

    const sendFieldEvents = (fields: string[], action: 'field_unsuccess' | 'field_success') => {
        if (fields.length > 0) {
            fields.forEach(name => {
                universalEvent({
                    ...fieldGtag,
                    action,
                    label: fieldGtag.label.replace('{Label}', `form_${name}`),
                });
            });
        }
    };

    const onSubmit = async finalData => {
        const filteredFinalData = removePropertyFromObject(finalData, 'accept');
        const formData = getFormDataFromHookForm({
            ...filteredFinalData,
            lnd: LNDAttribution.getMetrics(),
            non_lnd: Attribution.getMetrics(),
        });

        try {
            const response = await apiRequest.post<IBXResponseForms<{ message: string }>>(
                `/forms/internshipTwoZeroTwoFour/send`,
                formData
            );

            if (response?.data?.errors.length > 0) {
                setMessage('К сожалению, произошла ошибка при отправке формы. Попробуйте позднее.');
                throw new Error(response?.data?.errors[0].message);
            } else {
                setMessage('Форма успешно отправлена!');
                universalEvent(submitGtag);
                const fields: string[] = Object.keys(finalData);
                sendFieldEvents(fields, 'field_success');
            }
        } catch (e) {
            setMessage('К сожалению, произошла ошибка при отправке формы. Попробуйте позднее.');
        } finally {
            reset();
        }
    };

    const onVacancyChange = async (value: ISelectElement<string>) => {
        setValue('direction', value.value);
        await trigger('direction');
    };

    const onEducationFormChange = async (value: ISelectElement<string>) => {
        setValue('type_of_education', value.value);
        await trigger('type_of_education');
    };

    const onCourseChange = async (value: ISelectElement<string>) => {
        setValue('class', value.value);
        await trigger('class');
    };

    const onFileChange = (files: File[]) => setValue('file', files);

    useEffect(() => {
        if (titleRef && isOpen && needFocus) {
            titleRef?.current?.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    }, [isOpen]);

    useEffect(() => {
        if (isOpen && predefinedVacancy) {
            setValue('direction', predefinedVacancy.value);
        }
    }, [isOpen]);

    useEffect(() => {
        const fields: string[] = Object.keys(errors);
        sendFieldEvents(fields, 'field_unsuccess');
    }, [errors]);

    if (!!message && !messageInModal) {
        return <Title title={message} level={2} className={cn.title} />;
    }
    return (
        <FormProvider {...methods}>
            <form className={cn.form} onSubmit={handleSubmit(onSubmit)} noValidate key={`key_${message}`}>
                <ModalWrapper handleClose={closeMessage} isOpen={!!message}>
                    <Title title={message} level={2} className={cn.title} />
                </ModalWrapper>
                <div className={cn.titleRef} ref={titleRef}>
                    <Title title="Заявка на стажировку" level={2} className={cn.title} />
                </div>
                <div className={cn.fields}>
                    <div className={cn.input}>
                        <CustomSelect
                            {...register('direction')}
                            withPortal={false}
                            defaultValue={predefinedVacancy}
                            name="vacancy"
                            onChange={onVacancyChange}
                            options={options}
                            placeholder="Направление стажировки *"
                            isError={getFormErrors(errors, 'direction')?.[0]}
                            errorText={getFormErrors(errors, 'direction')?.[1]}
                        />
                    </div>
                    <div className={cn.input}>
                        <CustomSelect
                            {...register('type_of_education')}
                            withPortal={false}
                            name="educationForm"
                            onChange={onEducationFormChange}
                            options={EDUCATION_FORMS}
                            placeholder="Форма обучения *"
                            isError={getFormErrors(errors, 'type_of_education')?.[0]}
                            errorText={getFormErrors(errors, 'type_of_education')?.[1]}
                        />
                    </div>
                    <div className={cn.input}>
                        <Input
                            {...register('univercity')}
                            isError={getFormErrors(errors, 'univercity')?.[0]}
                            errorText={getFormErrors(errors, 'univercity')?.[1]}
                            customClass={cn.full}
                            shouldColored
                            type="text"
                            label="Университет *"
                        />
                    </div>
                    <div className={cn.input}>
                        <CustomSelect
                            {...register('class')}
                            withPortal={false}
                            name="course"
                            onChange={onCourseChange}
                            options={COURSES}
                            placeholder="Курс *"
                            isError={getFormErrors(errors, 'class')?.[0]}
                            errorText={getFormErrors(errors, 'class')?.[1]}
                        />
                    </div>
                    <div className={cn.input}>
                        <Input
                            {...register('city')}
                            isError={getFormErrors(errors, 'city')?.[0]}
                            errorText={getFormErrors(errors, 'city')?.[1]}
                            customClass={cn.full}
                            shouldColored
                            type="text"
                            label="Город *"
                        />
                    </div>
                    <div className={cn.input}>
                        <Input
                            {...register('fio')}
                            type="text"
                            label="ФИО *"
                            shouldColored
                            isError={getFormErrors(errors, 'fio')?.[0]}
                            errorText={getFormErrors(errors, 'fio')?.[1]}
                            customClass={cn.full}
                        />
                    </div>
                    <div className={clsx(cn.input, cn.inputMask)}>
                        <Input
                            {...register('phone')}
                            type="tel"
                            label="Телефон *"
                            mask="+7 (999) 999-99-99"
                            shouldColored
                            isError={getFormErrors(errors, 'phone')?.[0]}
                            errorText={getFormErrors(errors, 'phone')?.[1]}
                            customClass={cn.full}
                        />
                    </div>
                    <div className={cn.input}>
                        <Input
                            {...register('email')}
                            isError={getFormErrors(errors, 'email')?.[0]}
                            errorText={getFormErrors(errors, 'email')?.[1]}
                            customClass={cn.full}
                            shouldColored
                            type="text"
                            label="E-mail *"
                        />
                    </div>
                    <div className={clsx(cn.input, cn.file)}>
                        <FileUpload
                            {...register('file')}
                            type="secondary"
                            onFileChange={onFileChange}
                            label="Добавить резюме"
                            fileTypes={['.doc', '.pdf']}
                        />
                    </div>
                </div>
                <div className={cn.submit}>
                    <Button
                        variant="btnBlue"
                        buttonType="submit"
                        type="button"
                        label="Отправить заявку"
                        disable={isSubmitting}
                        customClass={cn.button}
                        animation={false}
                    />
                </div>
            </form>
        </FormProvider>
    );
}

export default InternshipForm;
