import React from 'react';
import SwiperCore, { Autoplay, EffectFade } from 'swiper';

import CatSlider from '@/components/blocks/Internship2024/CatSlider';
import Conditions from '@/components/blocks/Internship2024/Conditions';
import Faq from '@/components/blocks/Internship2024/Faq';
import Footer from '@/components/blocks/Internship2024/Footer';
import HasQuestion from '@/components/blocks/Internship2024/HasQuestion';
import Header from '@/components/blocks/Internship2024/Header';
import Hero from '@/components/blocks/Internship2024/Hero';
import Internship from '@/components/blocks/Internship2024/Internship';
import Jobs from '@/components/blocks/Internship2024/Jobs';
import Offer from '@/components/blocks/Internship2024/Offer';
import { InternshipFormProvider } from '@/components/blocks/Internship2024/providers/InternshipProvider';
import Stages from '@/components/blocks/Internship2024/Stages';
import VideoPreview from '@/components/blocks/Internship2024/VideoPreview';
import { withBlockLinks } from '@/components/HOC/withBlockLinks';

import cn from './style.module.sass';

export interface IInternLanding {
    _template?: 'internshipTwoZeroTwoFour';
}

SwiperCore.use([EffectFade, Autoplay]);

function InternLanding({ _template }: IInternLanding) {
    return (
        <InternshipFormProvider>
            <div className={cn.layout} id="wrapper">
                <div className={cn.wrapper}>
                    <Header />
                    <main className={cn.content}>
                        <Hero />
                        <Offer />
                        <Jobs />
                        <Conditions />
                        <Stages />
                        <Internship />
                        <VideoPreview />
                        <CatSlider />
                        <Faq />
                        <HasQuestion />
                    </main>
                </div>
                <Footer />
            </div>
        </InternshipFormProvider>
    );
}

export default withBlockLinks(InternLanding);
