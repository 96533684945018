import React from 'react';

import { ArrowIcon } from '../../ArrowIcon';
import cn from './style.module.sass';

interface IJobsVacancyProps {
    onClick?: (vacancy: string) => void;
    vacancy: string;
}

function JobsVacancy({ vacancy, onClick }: IJobsVacancyProps) {
    return (
        <div className={cn.vacancy} onClick={() => onClick(vacancy)} role="presentation">
            <div className={cn.text}>{vacancy}</div>
            <div className={cn.arrow}>
                <ArrowIcon />
            </div>
        </div>
    );
}

export default JobsVacancy;
