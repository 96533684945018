import React from 'react';

import Button from '@/components/blocks/Internship2024/Button';
import { GTAG } from '@/components/blocks/Internship2024/data/gtag';
import JobsSlider from '@/components/blocks/Internship2024/Jobs/JobsSlider';
import { useInternshipForm } from '@/components/blocks/Internship2024/providers/InternshipProvider';
import { universalEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function Jobs() {
    const { handleOpen } = useInternshipForm();
    const onClick = () => {
        universalEvent(GTAG.vacancy);
        handleOpen();
    };

    return (
        <div className={cn.wrapper} id="direction">
            <div className={cn.title}>Выбери направление стажировки</div>
            <div className={cn.list}>
                <JobsSlider />
            </div>
            <div className={cn.button}>
                <Button onClick={onClick} customClass={cn.button} isNewDesign type="button" label="Подать заявку" />
            </div>
        </div>
    );
}

export default Jobs;
