import React from 'react';

import Button from '@/components/blocks/Internship2024/Button';
import { GTAG } from '@/components/blocks/Internship2024/data/gtag';
import InternMenu from '@/components/blocks/Internship2024/Menu';
import { useInternshipForm } from '@/components/blocks/Internship2024/providers/InternshipProvider';
import { universalEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function FooterRight() {
    const { handleOpen } = useInternshipForm();

    const onClick = () => {
        universalEvent(GTAG.footer);
        handleOpen();
    };

    return (
        <div className={cn.wrapper}>
            <div className={cn.menu}>
                <InternMenu />
            </div>

            <div className={cn.button}>
                <Button type="button" label="Подать заявку" onClick={onClick} />
            </div>
        </div>
    );
}

export default FooterRight;
