import React, { createContext, ReactNode, useCallback, useContext, useMemo, useState } from 'react';

import { GTAG } from '@/components/blocks/Internship2024/data/gtag';
import InternshipForm from '@/components/blocks/Internship2024/InternshipForm';
import { isSelect } from '@/components/blocks/Internship2024/types/form';
import ModalWrapper from '@/components/modal/ModalWrapper';
import { ISelectElement } from '@/types/select';

import cn from './style.module.sass';

type ReturnInternshipFormType = {
    handleOpen: (value?: string | undefined | null) => void;
    handleClose: () => void;
};

export const InternshipFormContext = createContext<ReturnInternshipFormType>({} as ReturnInternshipFormType);

type InternshipFormProviderArgs = { children: ReactNode };

export const InternshipFormProvider = ({ children }: InternshipFormProviderArgs) => {
    const [open, setOpen] = useState(false);
    const [vacancy, setVacancy] = useState<ISelectElement<string> | null>(null);

    const handleOpen = useCallback((value?: string | undefined | null) => {
        setOpen(true);
        if (value && isSelect(value)) {
            setVacancy({ value, label: value });
        }
    }, []);

    const handleClose = useCallback(() => {
        setOpen(false);
        setVacancy(null);
    }, []);

    const value = useMemo(
        () => ({
            handleOpen,
            handleClose,
        }),
        []
    );

    return (
        <InternshipFormContext.Provider value={value}>
            <>
                {children}
                <ModalWrapper
                    isOpen={open}
                    handleClose={handleClose}
                    handleOpen={handleOpen}
                    wrapperClassName={cn.modalWrapper}
                    bgClassName={cn.modalOverlay}
                    className={cn.modalInner}
                >
                    <InternshipForm
                        fieldGtag={GTAG.formModalField}
                        submitGtag={GTAG.formModalSuccess}
                        needFocus
                        isOpen={open}
                        title="Заявка на стажировку"
                        predefinedVacancy={vacancy}
                    />
                </ModalWrapper>
            </>
        </InternshipFormContext.Provider>
    );
};

export const useInternshipForm = () => useContext(InternshipFormContext);
