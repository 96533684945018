import clsx from 'clsx';
import React from 'react';

import Button from '@/components/blocks/Internship2024/Button';
import { conditions } from '@/components/blocks/Internship2024/data/conditions';
import { GTAG } from '@/components/blocks/Internship2024/data/gtag';
import { useInternshipForm } from '@/components/blocks/Internship2024/providers/InternshipProvider';
import CustomImage from '@/components/CustomImage';
import { universalEvent } from '@/components/shared/utilities/analytics/metrics';

import cn from './style.module.sass';

function Conditions() {
    const { handleOpen } = useInternshipForm();

    const onClick = () => {
        universalEvent(GTAG.conditions);
        handleOpen();
    };

    return (
        <div className={cn.wrapper} id="conditions">
            <div className={cn.title}>Условия</div>
            <div className={cn.list}>
                {conditions.map(item => (
                    <div className={clsx(cn.item)} key={item.title}>
                        <div className={cn.left}>
                            <div className={cn.itemTitle}>{item.title}</div>
                            <div className={cn.desc} dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                        </div>
                        <div className={cn.right}>
                            <CustomImage fill src={item.icon} className={cn.icon} alt="icon" />
                        </div>
                    </div>
                ))}
            </div>
            <div className={cn.button}>
                <Button onClick={onClick} customClass={cn.button} isNewDesign type="button" label="Хочу в команду" />
            </div>
        </div>
    );
}

export default Conditions;
