import clsx from 'clsx';
import React from 'react';

import { menu } from '@/components/blocks/Internship2024/data/header';

import cn from './style.module.sass';

export interface IInternMenu {
    closeMenu?: () => void;
    dark?: boolean;
}

function InternMenu({ closeMenu, dark = false }: IInternMenu) {
    const onClick = (e: React.MouseEvent<HTMLButtonElement | HTMLAnchorElement>, id?: string) => {
        e.preventDefault();
        if (closeMenu) {
            closeMenu();
        }
        const element = document.getElementById(id);

        if (element) {
            element.scrollIntoView({ block: 'start', behavior: 'smooth' });
        }
    };

    return (
        <div className={clsx(cn.menu)}>
            {menu.list?.map(item => (
                <a
                    className={clsx(cn.item, dark && cn.dark)}
                    key={item.id}
                    href="/"
                    onClick={event => onClick(event, item.id)}
                >
                    {item.title}
                </a>
            ))}
        </div>
    );
}

export default InternMenu;
