import clsx from 'clsx';
import React, { useRef } from 'react';

import cn from './style.module.sass';

interface IInternButtonProps {
    /**
     * Тип: кнопка или ссылка
     */
    type: 'button' | 'link';
    /**
     * Ссылка
     */
    href?: string;
    /**
     * Target атрибут
     */
    target?: '_blank' | '_self' | '_parent' | '_top';
    /**
     * Размеры кнопки
     */
    size?: 'large' | 'small';
    /**
     * Содержимое кнопки
     */
    label: string;
    /**
     * Активность кнопки
     */
    disable?: boolean;
    /**
     * Необязательный обработчик щелчков
     */
    onClick?: () => void;
    /**
     * Дополнительный класс
     */
    customClass?: string;
    /**
     * Темный цвет
     */
    isDark?: boolean;
    /**
     * Заливка
     */
    isBg?: boolean;
    /**
     * Тип кнопки
     */
    buttonType?: 'button' | 'submit' | 'reset';
    isNewDesign?: boolean;
}

function Button({
    label,
    href,
    target,
    onClick,
    buttonType = 'button',
    isDark = false,
    isBg = false,
    disable = false,
    customClass,
    type = 'button',
    size = 'large',
    isNewDesign,
}: IInternButtonProps) {
    const ref = useRef(null);

    const className = clsx(
        cn.btnIntern,
        disable === true && cn.disable,
        isDark === true && cn.darkTheme,
        isBg === true && cn.bgTheme,
        size === 'small' && cn.small,
        isNewDesign && cn.isNewDesign,
        customClass
    );

    if (type !== 'button')
        return (
            <a href={href} ref={ref} target={target} className={className} onClick={onClick}>
                {label}
            </a>
        );

    return (
        <button
            ref={ref}
            // eslint-disable-next-line react/button-has-type
            type={buttonType}
            className={className}
            onClick={onClick}
            disabled={disable}
        >
            {label}
        </button>
    );
}

export default Button;
