import React from 'react';

import cn from './style.module.sass';

const list = [
    {
        title: 'ЧТО ПРЕДЛАГАЕМ ',
        subtitle:
            'Стажировка по&nbsp;направлениям: аналитика CRM, PR, HR, ИБ, интернет-продажи с&nbsp;окладом 55&nbsp;000&nbsp;₽',
    },
    {
        title: 'КОГО ЖДЕМ',
        subtitle: 'Студенты от&nbsp;3&nbsp;курса бакалавриата',
    },
    {
        title: 'КАК ПОПАСТЬ',
        subtitle: 'Подать заявку и&nbsp;пройти отбор: количество мест ограничено',
    },
];

function Offer() {
    return (
        <div className={cn.wrapper}>
            <div className={cn.grid}>
                {list.map(item => (
                    <div className={cn.item} key={item.title}>
                        <div className={cn.title}>
                            <span className={cn.decor}>/</span>
                            {item.title}
                        </div>
                        <div className={cn.desc} dangerouslySetInnerHTML={{ __html: item.subtitle }} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Offer;
