import React from 'react';

import cn from '@/components/blocks/Internship2024/style.module.sass';

interface IProps {
    handleClick?: () => void;
}

export function BurgerIcon({ handleClick }: IProps) {
    return (
        <div onClick={handleClick} role="presentation">
            <svg width="37" height="22" viewBox="0 0 37 22" fill="none" className={cn.icon}>
                <rect x="0.394165" width="36" height="2" fill="#1B1A1C" />
                <rect x="0.394165" y="10" width="36" height="2" fill="#1B1A1C" />
                <rect x="0.394165" y="20" width="36" height="2" fill="#1B1A1C" />
            </svg>
        </div>
    );
}
