import React from 'react';

import { IVacancyContent } from '@/components/blocks/Internship2024/types/jobs';

import cn from './style.module.sass';

interface VacancyInfoItemProps {
    item: IVacancyContent;
}
const VacancyInfoItem = ({ item }: VacancyInfoItemProps) => (
    <div className={cn.wrapper}>
        <div className={cn.title} dangerouslySetInnerHTML={{ __html: item.title }} />
        <ul className={cn.content}>
            {item.subtitle.map(subtitle => (
                <li key={subtitle} className={cn.subtitle} dangerouslySetInnerHTML={{ __html: subtitle }} />
            ))}
        </ul>
    </div>
);

export default VacancyInfoItem;
