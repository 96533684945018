import React from 'react';

import { internship } from '@/components/blocks/Internship2024/data/internship';
import CustomImage from '@/components/CustomImage';

import cn from './style.module.sass';

function Internship() {
    return (
        <div className={cn.wrapper}>
            <div className={cn.title}>Стажировка у нас это</div>
            <div className={cn.list}>
                {internship.map((item, idx) => (
                    <div className={cn.item} key={item.title}>
                        <div className={cn.icon}>
                            <CustomImage fill src={item.icon} alt="internshipIcon" />
                        </div>
                        <div className={cn.desc} dangerouslySetInnerHTML={{ __html: item.title }} />
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Internship;
