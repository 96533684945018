import React from 'react';

import cn from '@/components/blocks/Internship2024/style.module.sass';

interface IProps {
    handleClick?: () => void;
}

export function CrossIcon({ handleClick }: IProps) {
    return (
        <div onClick={handleClick} role="presentation">
            <svg
                width="26"
                height="26"
                viewBox="0 0 26 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
                className={cn.iconCross}
            >
                <path d="M2.60156 2.6001L23.4016 23.4001M2.60156 23.4001L23.4016 2.6001" strokeWidth="1.4" />
            </svg>
        </div>
    );
}
