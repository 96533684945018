import React from 'react';

import { faq } from '@/components/blocks/Internship2024/data/faq';
import FaqItem from '@/components/blocks/Internship2024/Faq/FaqItem';

import cn from './style.module.sass';

function InternFaq() {
    return (
        <div className={cn.wrapper} id="faq">
            <div className={cn.title}>FAQ</div>
            <div className={cn.list}>
                {faq.map(({ title, subtitle }, index) => (
                    <FaqItem title={title} subtitle={subtitle} key={title} />
                ))}
            </div>
        </div>
    );
}

export default InternFaq;
