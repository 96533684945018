export const conditions = [
    {
        icon: '/img-next/svg/internship2024/conditions/1.svg',
        title: 'ЗАРПЛАТА',
        subtitle: '55&nbsp;000&nbsp;₽ Gross',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/2.svg',
        title: 'ОФОРМЛЕНИЕ',
        subtitle: 'Официальное оформление по&nbsp;срочному договору',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/3.svg',
        title: 'ПРОДОЛЖИТЕЛЬНОСТЬ',
        subtitle: '3&nbsp;месяца (июль&nbsp;&mdash; сентябрь) + 4&nbsp;месяца, как приятный бонус',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/4.svg',
        title: 'ПРОЕКТЫ',
        subtitle: 'Погружение в&nbsp;интересные задачи и&nbsp;участие в&nbsp;реальных проектах',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/5.svg',
        title: 'ГРАФИК',
        subtitle:
            'Полный рабочий день, 40&nbsp;ч в&nbsp;неделю летом и&nbsp;не&nbsp;менее 20&nbsp;ч в&nbsp;неделю с&nbsp;1&nbsp;сентября',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/6.svg',
        title: 'ФОРМАТ',
        subtitle: 'Очно в&nbsp;головном офисе и&nbsp;гибрид',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/7.svg',
        title: 'ОБУЧЕНИЕ',
        subtitle: 'Тренинги по&nbsp;soft-навыкам и&nbsp;очень много hard!',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/8.svg',
        title: 'НАСТАВНИК',
        subtitle: 'Поддерживающие встречи с&nbsp;наставником',
    },
    {
        icon: '/img-next/svg/internship2024/conditions/9.svg',
        title: 'КАРЬЕРНЫЙ СТАРТ',
        subtitle: 'Возможность продолжить карьеру в&nbsp;стабильном банке',
    },
];
