import clsx from 'clsx';
import React from 'react';

import cn from './style.module.sass';

interface IProps {
    category: string;
}

function Category({ category }: IProps) {
    return <div className={clsx(cn.category, !category && cn.hidden)}>{category}</div>;
}

export default Category;
