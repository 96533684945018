import { GTagEvent } from '@/components/shared/utilities/analytics/metrics';

export const GTAG: Record<string, GTagEvent> = {
    hero: {
        eventName: 'pbSiteEvent',
        category: 'Interactions',
        action: 'click',
        label: 'internship2024_stazhirovka_first_block',
    },
    footer: {
        eventName: 'pbSiteEvent',
        category: 'Interactions',
        action: 'click',
        label: 'internship2024_stazhirovka_footer',
    },
    vacancy: {
        eventName: 'pbSiteEvent',
        category: 'Interactions',
        action: 'click',
        label: 'internship2024_stazhirovka_podat_zayavku',
    },
    conditions: {
        eventName: 'pbSiteEvent',
        category: 'Interactions',
        action: 'click',
        label: 'internship2024_stazhirovka_hochu_v_komandu',
    },
    formModalSuccess: {
        eventName: 'pbFormEvent',
        category: 'AppSent',
        action: 'internship2024:stazhirovka',
        label: 'https://www.pochtabank.ru/internship2024',
    },
    formModalField: {
        eventName: 'pbFormEvent',
        category: 'Interactions',
        action: 'field_success',
        label: 'internship2024_{Label}',
    },
    formInlineSuccess: {
        eventName: 'pbFormEvent',
        category: 'AppSent',
        action: 'internship2024:zayavka',
        label: 'https://www.pochtabank.ru/internship2024',
    },
    formInlineField: {
        eventName: 'pbFormEvent',
        category: 'Interactions',
        action: 'field_success',
        label: 'internship2024_zayavka_{Label}',
    },
};
