export const stages = [
    {
        number: '01',
        stage: 'Подача заявки',
        description:
            'Заполни форму заявки <span class="stageModal">здесь</span>.<br/>Заявки принимаются до&nbsp;16&nbsp;июня 2024',
    },
    {
        number: '02',
        stage: 'Знакомство с&nbsp;рекрутером',
        description: 'Пройди небольшое собеседование онлайн',
    },
    {
        number: '03',
        stage: 'Выполнение тестового задания',
        description: 'У&nbsp;тебя есть 1&nbsp;неделя на&nbsp;выполнение,<br/>оцениваются качество и&nbsp;скорость',
    },
    {
        number: '04',
        stage: 'Собеседование с&nbsp;руководителем',
        description: 'Пройди собеседование с&nbsp;будущим руководителем',
    },
    {
        number: '05',
        stage: 'Оформление на стажировку',
        description:
            'Подпиши договор и&nbsp;ты&nbsp;в&nbsp;команде!<br/>Стажировка начинается 1&nbsp;июля 2024.<br/>Мы&nbsp;ждем тебя!',
    },
];
